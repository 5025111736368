<script setup>
import {useBaseStore} from "~/stores/base";

const router = useRouter()
const route = useRoute()

/** emits **/
const emits = defineEmits(["change"])

/** props **/
const props = defineProps({})

/** data **/
const checked = ref(false)

/** computed **/
const isMobile = computed(() => useBaseStore().getterIsMobile)
const userInfo = computed(() => useBaseStore().getterUserInfo)

/** watch **/
watch(checked, () => {
  emits("change", checked.value)
})

/** methods **/

/** lifecycle **/

</script>

<template>
  <div class="module-place-order-tips-mobile" v-if="isMobile">
    <el-checkbox v-model="checked" size="large"/>
    <div>
      Please note that after your order is placed, we are not able to modify or exchange any items from your BNTO box. In the event an item is unavailable, we will substitute your item for a similar style or size.
    </div>
  </div>
  <div class="module-place-order-tips-desktop" v-else>
    <el-checkbox v-model="checked" size="large"/>
    <div>
      Please note that after your order is placed, we are not able to modify or exchange any items from your BNTO box. In the event an item is unavailable, we will substitute your item for a similar style or size.
    </div>
  </div>
</template>

<style lang="scss">
.module-place-order-tips-mobile {
  .el-checkbox {
    height: fit-content !important;
  }

  .el-checkbox__inner {
    width: 16px !important;
    height: 16px !important;

    &:after {
      left: 5px;
      top: 2px;
    }
  }
}

.module-place-order-tips-desktop {
  .el-checkbox {
    height: fit-content !important;
  }

  .el-checkbox__inner {
    width: 16px !important;
    height: 16px !important;

    &:after {
      left: 5px;
      top: 2px;
    }
  }
}
</style>

<style scoped lang="scss">
@import "src/assets/config";

.module-place-order-tips-mobile {
  padding: 16px 20px;
  border-top: 1px solid $color-gray-300;
  display: flex;
  gap: 8px;

  /* Text sm/Regular */
  font-family: "TWK Lausanne";
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: 20px; /* 142.857% */

  .el-checkbox {
    margin-top: 2px;
  }
}

.module-place-order-tips-desktop {
  padding: 16px 20px;
  border-top: 1px solid $color-gray-300;
  display: flex;
  gap: 8px;

  /* Text sm/Regular */
  font-family: "TWK Lausanne";
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: 20px; /* 142.857% */

  .el-checkbox {
    margin-top: 2px;
  }
}
</style>
