import {config} from "~/utils/config.js";
import {omniFetch} from "~/utils/fetch.js";

export function mobileSendVerifyCode({mobile, businessType}) {
  return omniFetch({
    url: config.v1Path + config.mobileSendVerifyCodePath,
    method: "post",
    params: {mobile, businessType} // userInfo
  })
}

export function mobileCheckVerifyCode({mobile, verifyCode}) {
  return omniFetch({
    url: config.v1Path + config.mobileCheckVerifyCodePath,
    method: "post",
    params: {mobile, verifyCode}
  })
}
